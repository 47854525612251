import { ScrollView, StyleSheet } from "react-native";
import CallWarning from "./CallQualityWarning/index.web";
import { useContext } from "react";
import { useMemo } from "react";
import CallQualityWarningsContext from "../../contexts/CallQualityWarningsContext";
import SolidButton from "../../components/Buttons/SolidButton";
import palette from "../../styles/palette";
import { Strings } from "../../constants/strings";

function CallQualityWarningsStackModal() {
  const { warnings, hideWarning, hideAllWarnings, hiddenWarnings } = useContext(
    CallQualityWarningsContext,
  );

  const getWarningsToRender = () => {
    const visibleWarnings = warnings?.filter((warning) => {
      const warningName = warning.name;
      const callSids = warning.callSids;
      const hiddenCallSids = hiddenWarnings[warningName] || [];
      const callsSidsVisible = callSids.filter(
        (callSid) => !hiddenCallSids.includes(callSid),
      );
      return callsSidsVisible.length > 0;
    });

    return visibleWarnings;
  };
  const warningsToRender = getWarningsToRender();
  const hasMultipleWarnings = warningsToRender.length > 1;

  const onClear = (name) => {
    hideWarning(name);
  };

  if (warningsToRender.length === 0) {
    return null;
  }

  return (
    <ScrollView style={styles.call_quality_warning_stack_container}>
      {warningsToRender.map((warning) => (
        <CallWarning key={warning?.name} warning={warning} onClear={onClear} />
      ))}
      {hasMultipleWarnings && (
        <SolidButton
          color={palette.warning_darker}
          label={Strings.CALL_QUALITY_WARNINGS_CLEAR_ALL_LABEL}
          labelStyle={styles.call_quality_warning_stack_clear_btn_container}
          onPress={hideAllWarnings}
          mt={4}
        />
      )}
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  call_quality_warning_stack_container: {
    position: "absolute",
    right: 0,
    zIndex: 1000,
    padding: 16,
    width: "25%",
    bottom: 0,
  },
  call_quality_warning_stack_clear_btn_container: {
    color: palette.black,
  },
});

export default CallQualityWarningsStackModal;
