import { CALL_QUALITY_WARNING_HIGH_PACKET_LOSS_FRACTION } from "../constants/callQualityWarning";
import { sortByTimestamp } from "../helpers/sort";
import { List } from "./base";
import { CallQualityWarning } from "./CallQualityWarning";
export default class CallQualityWarningService extends List {
  hiddenWarnings = {};

  constructor(data) {
    if (data.warnings) {
      super(data.warnings);
    }

    if (data.hiddenWarnings) {
      this.hiddenWarnings = data.hiddenWarnings;
    }
  }

  get warnings() {
    if (!this.models.length) return [];
    return sortByTimestamp(this.models);
  }

  add(data) {
    const model = new this.model(data);
    this.models.push(model);
  }

  addCallSid(warning, callSid) {
    const newWarning = new CallQualityWarning(warning);
    newWarning.callSids.push(callSid);
    return newWarning;
  }

  remove(model) {
    this.models = this.models.filter((warning) => warning.name !== model.name);
  }

  addWarning(callSid, warningName) {
    if (warningName === CALL_QUALITY_WARNING_HIGH_PACKET_LOSS_FRACTION) return;
    const warning = this.models.find((warning) => warning.name === warningName);
    if (warning) {
      const newWarning = this.addCallSid(warning, callSid);
      this.remove(warning);
      this.models.push(newWarning);
    } else {
      this.add({ name: warningName, callSid });
    }
  }

  removeWarning(warningName) {
    this.models = this.models.filter((warning) => warning.name !== warningName);
  }

  hideWarning(warningName) {
    const warning = this.models.find((warning) => warning.name === warningName);
    if (!warning) return;
    if (this.hiddenWarnings[warningName]) {
      this.hiddenWarnings[warningName] = this.hiddenWarnings[
        warningName
      ].concat(warning.callSids);
    } else {
      this.hiddenWarnings[warningName] = warning.callSids;
    }
  }

  hideAllWarnings() {
    this.models.forEach((warning) => {
      const warningName = warning.name;
      if (this.hiddenWarnings[warningName]) {
        this.hiddenWarnings[warningName] = this.hiddenWarnings[
          warningName
        ].concat(warning.callSids);
      } else {
        this.hiddenWarnings[warningName] = warning.callSids;
      }
    });
  }

  get model() {
    return CallQualityWarning;
  }
}
